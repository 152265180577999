<template>
  <div class="create-pmp-ad">
    <b-card>
      <div class="heading">
        <p>Add External Ad</p>
      </div>
      <!-- form -->
      <validation-observer ref="addPMPAd">
        <b-form
          class="mt-2"
          style="width: 100%"
          @submit.prevent
        >
          <b-row
            class="d-flex justify-content-center"
          >
            <b-col
              md="9"
              lg="9"
              xl="9"
              class="mx-auto"
            >
              <b-form-group
                label="External Ad Name"
                class="label"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="External Ad Name"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-title"
                    v-model="postData.name"
                    placeholder="Please enter External Ad name here"
                    style="height: 42px"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="isAgency || hasAgencyPermission"
              md="5"
              lg="5"
              xl="5"
            >
              <b-form-group
                label="Brands"
                class="label"
              >
                <v-select
                  v-model="postData.brand"
                  class="zindex-7"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="allBrands"
                  :reduce="selectCategoriesType => selectCategoriesType.id"
                  placeholder="Select Brands"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="isAgency || hasAgencyPermission"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label="Brand Margin"
                class="label"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Brand Margin"
                  :rules="isAgency || hasAgencyPermission ? 'required|numeric|min_value:0|max_value:100' : ''"
                >
                  <b-form-input
                    id="blog-edit-title"
                    v-model.number="postData.margin"
                    type="number"
                    :max="100"
                    :min="0"
                    placeholder="Please enter Brand Margin here"
                    style="height: 42px"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-col
        md="12"
        class="mx-auto pt-2"
      >
        <b-card
          class="mt-1 card2 p-1"
        >
          <div class="d-flex justify-content-between pb-4">
            <span
              class="d-flex justify-items-between label labelSelectedDevices"
            >
              <span>Selected Devices</span>
              <span
                class="ml-2 badge bg-primary d-flex align-items-center"
              >
                Total Impressions : {{ Math.floor(totalImpressionsDevices) }}</span>
            </span>
          </div>
          <span
            v-for="(item, index) in selectedDeviceIds"
            :key="index"
          >
            <b-badge
              variant="success"
              class="mr-2 mb-2 px-2 py-1 cursor-pointer"
            >
              <p
                class="text-id badge-class text-truncate"
              >
                {{ item.venue_name }}-{{ item.name }}
              </p>
            </b-badge>
          </span>
        </b-card>
      </b-col>
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        @addMediaToCampaign="addMediaToPMPAd"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        :is-draggable="true"
        @removeMediaFromList="deleteMediaFromPMPAd"
        @changeOnTotalDurationChanged="changeOnTotalDurationChanged"
        @changedOrderOfList="changedOrderOfList"
      />
      <AddTimeSlotsAdCampaign
        ref="add-time-slots"
        :dates-for-time-slots="selectedDates"
        :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
        :total-duration="totalDurationMedia"
        @addDatesWithTimeSlotsToArray="addDatesWithTimeSlotsToArray"
        @notSetTimeSlot="notSetTimeSlot"
      />
      <viewListDatesWithTimeSlots
        ref="view-time-slots"
        :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
        @addDatesWithTimeSlotsToArrayAfterViewingList="addDatesWithTimeSlotsToArrayAfterViewingList"
      />
      <b-row
        md="12"
        lg="12"
        class="mb-2 px-1"
      >
        <b-col
          md="9"
          lg="9"
          class="mx-auto"
        >
          <b-form-group
            label="Select Time Zone"
            label-for="blog-edit-category"
            class="campaign-label"
          >
            <v-select
              v-model="postData.timezone"
              class="zindex-2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              select-size="3"
              :clearable="false"
              :searchable="true"
              :options="timezones"
              placeholder="Select Time Zone"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-col
        md="12"
        class="mx-auto"
      >
        <Calendar
          v-model="date"
          :attributes="attributes"
          is-expanded
          @dayclick="onDayClick"
        />
      </b-col>
      <div
        class="d-flex justify-content-center pb-2"
      >
        <b-button
          variant="outline-primary"
          class="my-1 mx-1"
          :disabled="disableSetTime || totalDurationMedia <= 0"
          @click="setTimeForSelectedDates"
        >
          Set Time
        </b-button>
        <b-button
          v-if="ifTimeSlotsExists"
          variant="outline-primary"
          class="my-1 mx-1"
          @click="editTimeForSelectedDates"
        >
          View Schedule
        </b-button>
      </div>
      <div class="d-flex justify-content-center mt-0 ">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewPMPAdsCampaign"
        >
          Review
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="openConfirmationModal"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Create
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelPmpAds"
        >
          Cancel
        </b-button>
      </div>
      <PmpCheckoutModal
        ref="pmp-ad-checkout"
        :post-data="postData"
      />
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BForm,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BRow,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import Vue from 'vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { Calendar } from 'v-calendar'
import TimeLimitCondition from '@/common/config'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import { showToast } from '@/common/global/functions'
import AccountTypes from '@/common/enums/accountTypeEnum'
import {
  convertToSchedulerFormat,
  getThreeDaysAheadDate,
  convertToSchedulerFormatToUTC,
  getAllTimezones,
} from '@/common/global/calenderFunctions'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import AddTimeSlotsAdCampaign from '@/components/pmp-ads/AddTimeSlotsAdCampaign.vue'
import viewListDatesWithTimeSlots from '@/components/bannerads/viewListDatesWithTimeSlots.vue'
import PmpCheckoutModal from '@/components/pmp-ads/PmpCheckoutModal.vue'

Vue.component('Calendar', Calendar)

Vue.prototype.moment = moment
export default {
  name: 'CreatePMPAd',
  components: {
    vSelect,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    AddedMediaToCampaign,
    BButton,
    BRow,
    BBadge,
    BannerAdsMedia,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    Calendar,
    viewListDatesWithTimeSlots,
    AddTimeSlotsAdCampaign,
    PmpCheckoutModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
    momentDateForLocalTime(date) {
      return moment.utc(`${date.date} ${date.time.HH}:${date.time.mm}`, 'YYYY-MM-DD HH:mm').local().format('MMM DD,YYYY HH:mm')
    },
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.PMP_ADS)
    const adminRole = store.getters['user/getUserRole']
    if (memberPermission || (adminRole === AccountTypes.ADMIN)) {
      next()
    } else {
      next({
        name: 'dashboard',
      })
    }
  },
  data() {
    return {
      mapLoading: false,
      initialLocation: {
        lat: 31.530443164751656, lng: 74.37880084073794,
      },
      mapZoomLevel: 10,
      spinner: false,
      addLocationSpinner: false,
      TimeLimitCondition,
      postData: {
        name: null,
        central_location: {},
        radius: 0,
        media_ids: [],
        address: null,
        device_ids: [],
        start_date: null,
        end_date: null,
        scheduler: [],
        ad_category_ids: [],
        timezone: null,
        brand: null,
        margin: null,
        user_type: null,
        amount: null,
        paymentMethodId: null,
      },
      devicesPaths: [],
      addedFilesToCampaign: [],
      limit: '',
      totalDevices: 0,
      selectedRadius: 0,
      number: {
        numeral: true,
      },
      required,
      date: getThreeDaysAheadDate(),
      days: [],
      arrayOfDatesCollected: [],
      disableSetTime: true,
      ifTimeSlotsExists: false,
      selectedDates: [],
      finalDatesWithTimeSlots: [],
      selectedDeviceIds: [],
      venue_type: [],
      totalDurationMedia: 0,
      adCategories: [],
      timezones: [],
      totalImpressionsDevices: 0,
      allBrands: [],
      hasAgencyPermission: false,
    }
  },
  computed: {
    userIP() {
      return this.$store.getters['user/getUserIP']
    },
    dates() {
      return this.days.map(day => day.date)
    },
    attributes() {
      const currentAttributes = this.dates.map(date => ({
        highlight: true,
        dates: date,
      }))
      const previousAttributes = this.arrayOfDatesCollected.map(id => ({
        bar: 'red',
        dates: id,
      }))

      return [...currentAttributes, ...previousAttributes]
    },
    venueList() {
      return this.$store.getters['venues/getVenueTypeList']
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
    isAnyDeviceSelected() {
      return this.selectedDeviceIds.length > 0
    },
    userGroupId() {
      return this.$store.getters['persistedStore/getSelectedGroupId']
    },
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
  },
  watch: {
    devicesPaths() {
      this.$refs['map-view-id'].devicesMarkerFunc(this.devicesPaths)
    },
    selectedRadius: {
      handler(value) {
        this.$refs['map-view-id'].selectedRadiusFunc(value.value)
        this.postData.radius = value.value
      },
    },
    selectedDeviceIds: {
      handler(newValue) {
        this.totalImpressionsDevices = newValue.reduce((total, device) => total + Number(device.impressions || 0), 0)
      },
      deep: true,
    },
  },
  async mounted() {
    const selectedVenue = this.$store.getters['pmpAds/getSelectedVenuesOnDashboard']
    this.postData.central_location = this.extractLatLng(selectedVenue[0].location)
    this.postData.address = selectedVenue[0].address
    this.postData.radius = 20
    this.selectedDeviceIds = selectedVenue.flatMap(venue => venue.device.map(device => ({
      cpm: device.cpm,
      id: device.id,
      impressions: device.impressions,
      name: device.name,
      venue_name: venue.venue_name,
      ...this.extractLatLng(device.location),
      revenue_percentage: venue.revenue_percentage,
    })))
    await this.$store.dispatch('venues/getVenueTypeList')
    if (this.isUserAdmin) {
      const permission = await this.$store.dispatch('brands/checkIfGroupHasAgencyPermission', {
        userHasGroupId: this.$route.params.userId,
      })
      this.hasAgencyPermission = permission.some(feature => feature.slug === MemberPermissions.AGENCY)
      this.adCategories = await this.$store.dispatch('pmpAds/getAdminAdCategories')
    } else {
      this.adCategories = await this.$store.dispatch('pmpAds/getAdCategories')
    }

    if (this.isUserAdmin && this.hasAgencyPermission) {
      const brands = await this.$store.dispatch('brands/getAllBrandsByUserHasGroupIdAdmin', {
        userHasGroupId: this.$route.params.userId, offset: 0, limit: 0, searchQuery: '',
      })
      this.allBrands = brands.data.data.results
    } else if (this.isAgency && !this.isUserAdmin) {
      const brands = await this.$store.dispatch('brands/getAllBrandsByUserHasGroupId', {
        userHasGroupId: this.userGroupId, offset: 0, limit: 0, searchQuery: '',
      })
      this.allBrands = brands.data.data.results
    }
    this.timezones = await getAllTimezones()
  },
  methods: {
    extractLatLng(pointStr) {
      // Remove the "POINT(" prefix and ")" suffix
      const trimmed = pointStr.replace('POINT(', '').replace(')', '')
      // Split the string by space and convert to numbers
      const [lng, lat] = trimmed.split(' ').map(Number)
      return { lat, lng }
    },
    async calculateAdImpressions(finalDatesWithTimeSlots, totalImpressionsDevices) {
      let totalslots = 0
      finalDatesWithTimeSlots.forEach(data => {
        totalslots += data.value.length
      })
      return totalslots * totalImpressionsDevices
    },
    async openConfirmationModal() {
      this.postData.device_ids = []
      await this.selectedDeviceIds.map(device => this.postData.device_ids.push(device.id))
      this.postData.scheduler = await convertToSchedulerFormat(this.finalDatesWithTimeSlots, this.selectedDeviceIds)
      this.postData.scheduler = await convertToSchedulerFormatToUTC(this.postData.timezone, this.postData.scheduler)
      this.$refs.addPMPAd.validate().then(async success => {
        if (success) {
          if ((this.isAgency || this.hasAgencyPermission) && !this.postData.brand) {
            showToast('Add External Ad', 'Please select the brand!', 'danger', 4000)
            return
          }
          if (Object.keys(this.postData.central_location).length !== 0) {
            if (this.postData.radius !== 0) {
              if (this.postData.device_ids.length !== 0) {
                if (this.addedFilesToCampaign.length !== 0) {
                  if (this.postData.timezone) {
                    if (this.postData.scheduler.length > 0) {
                      const totalAdImpression = await this.calculateAdImpressions(this.finalDatesWithTimeSlots, this.totalImpressionsDevices)
                      await this.getStartAndEndDate(this.postData.scheduler)
                      await this.calculateAmountForAdd()
                      this.$swal({
                        title: '<span class="font-weight-bolder">Book External Ad?</span>',
                        icon: 'warning',
                        html: `You want to book External Ad with ${this.selectedDeviceIds.length} devices<br>Total Ad Impressions: <span class="badge bg-primary mt-1">${Math.floor(totalAdImpression)}</span>`,
                        showCloseButton: false,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText: 'Yes',
                        confirmButtonAriaLabel: 'Thumbs up, great!',
                        cancelButtonAriaLabel: 'Thumbs down',
                        width: '550px',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                          cancelButton: 'btn btn-outline-danger ml-1',
                          icon: 'primary',
                        },
                        buttonsStyling: false,
                      })
                        .then(result => {
                          if (result.value) {
                            this.createPMPAds()
                          }
                        })
                    } else {
                      showToast('Add External Ad', 'Please add schedule to create Ad', 'danger', 4000)
                    }
                  } else {
                    showToast('Add External Ad', 'Please add Time Zone to create Ad', 'danger', 4000)
                  }
                } else {
                  showToast('Add External Ad', 'Please add media files to create Ad', 'danger', 4000)
                }
              } else {
                showToast('Add External Ad', 'Ad cannot be created without devices', 'danger', 4000)
              }
            } else {
              showToast('Add External Ad', 'Radius should not be Null!', 'danger', 4000)
            }
          } else {
            showToast('Add External Ad', 'Please select the location!', 'danger', 4000)
          }
        }
      })
    },
    async createPMPAds() {
      if (this.isAgency || this.hasAgencyPermission) {
        this.postData.user_type = 'agency'
      } else {
        this.postData.user_type = 'venue_owner'
      }
      this.$refs['pmp-ad-checkout'].show()
      // await this.addNewPMPAds()
    },
    cancelPmpAds() {
      this.$router.back()
    },
    updateIdsOfMediaInPayload() {
      this.postData.media_ids = this.addedFilesToCampaign.map(res => res.id)
    },
    addMediaToPMPAd(media) {
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.$swal.fire({
          text: 'All selected time slots will be lost!! are you sure you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Add',
          cancelButtonText: 'Cancel',
        }).then(result => {
          if (result.isConfirmed) {
            this.resetAllDatesOfCalendar()
            this.addedFilesToCampaign.push(media)
            this.updateIdsOfMediaInPayload()
          }
        })
      } else {
        this.addedFilesToCampaign.push(media)
        this.updateIdsOfMediaInPayload()
      }
    },
    deleteMediaFromPMPAd(index) {
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.$swal.fire({
          text: 'All selected time slots will be lost!! are you sure you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
        }).then(result => {
          if (result.isConfirmed) {
            this.resetAllDatesOfCalendar()
            this.addedFilesToCampaign.splice(index, 1)
            this.updateIdsOfMediaInPayload()
          }
        })
      } else {
        this.addedFilesToCampaign.splice(index, 1)
        this.updateIdsOfMediaInPayload()
      }
    },
    resetAllDatesOfCalendar() {
      this.finalDatesWithTimeSlots = []
      this.dates = []
      this.arrayOfDatesCollected = []
      this.setAllDatesAvailable()
      this.disableSetTime = true
      this.ifTimeSlotsExists = false
    },
    changedOrderOfList(array) {
      this.addedFilesToCampaign = array
      this.updateIdsOfMediaInPayload()
    },
    async reviewPMPAdsCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
    async onDayClick(day) {
      if (this.totalDurationMedia <= 0) {
        this.$swal.fire({
          text: 'Please add Media. Media is required to add slots.',
          icon: 'warning',
        })
      }
      const idx = this.days.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        this.days.splice(idx, 1)
        if (this.days.length === 0) {
          this.disableSetTime = true
          this.setAllDatesAvailable()
        }
      } else {
        this.disableSetTime = false
        this.days.push({
          id: day.id,
          date: day.date,
        })
      }
    },
    setTimeForSelectedDates() {
      this.days.forEach(element => this.selectedDates.push(element.id))
      this.$refs['add-time-slots'].show()
    },
    async editTimeForSelectedDates() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
      this.$refs['view-time-slots'].show()
    },
    addDatesWithTimeSlotsToArray(array) {
      this.days.forEach(element => this.arrayOfDatesCollected.push(element.id))
      this.days = []
      this.selectedDates = []
      this.disableSetTime = true
      this.setAllDatesAvailable()
      this.finalDatesWithTimeSlots = array
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.ifTimeSlotsExists = true
      }
    },
    notSetTimeSlot() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
    },
    setAllDatesAvailable() {
      this.days = []
      this.selectedDates = []
    },
    async addDatesWithTimeSlotsToArrayAfterViewingList(array) {
      this.arrayOfDatesCollected = []
      this.finalDatesWithTimeSlots = array
      await this.finalDatesWithTimeSlots.forEach(element => this.arrayOfDatesCollected.push(element.key))
      if (this.finalDatesWithTimeSlots.length === 0) {
        this.ifTimeSlotsExists = false
      }
    },
    locationSelected(device) {
      this.selectedDeviceIds.push(device)
    },
    locationRemoved(device) {
      this.selectedDeviceIds = this.selectedDeviceIds.filter(d => d.id !== device.id)
    },
    changeOnTotalDurationChanged(duration) {
      this.totalDurationMedia = duration
    },
    allDevicesSelected(allDevicesIDs) {
      this.selectedDeviceIds = allDevicesIDs
    },
    allDevicesDeselected() {
      this.selectedDeviceIds = []
    },
    selectAllDevices() {
      this.$refs['map-view-id'].selectAllDevices()
    },
    deselectAllDevices() {
      this.$refs['map-view-id'].deselectAllDevices()
    },
    getStartAndEndDate(data) {
      const startDates = data.map(item => new Date(item.start_date_time))
      const endDates = data.map(item => new Date(item.end_date_time))

      const minStartDate = new Date(Math.min(...startDates))
      const maxEndDate = new Date(Math.max(...endDates))

      this.postData.start_date = minStartDate.toISOString()
      this.postData.end_date = maxEndDate.toISOString()
    },
    calculateAmountForAdd() {
      let amount = 0
      this.postData.scheduler.forEach(element => {
        // Ensure free_impressions and cpm are valid numbers
        if (element.free_impressions && element.cpm) {
          amount += (element.free_impressions * element.cpm) / 1000
        }
      })
      if (this.postData.margin) {
        this.postData.amount = +(amount * (1 + this.postData.margin / 100)).toFixed(2)
      } else {
        this.postData.amount = +amount.toFixed(2)
      }
    },
  },
}
</script>

      <style lang="scss">
      @import '@core/scss/vue/libs/vue-flatpicker.scss';
      .create-pmp-ad{
        .time-picker{
          width: 100%;
          .custom-picker {
            padding: 0.438rem 1rem;
            border: 1px solid #d8d6de;
            border-radius: 0.357rem;
            height: 40px;
            width: 100%;
            font-size: 1.1rem;
            color: #6e6b7b;
            font-weight: 400;
            font-family: "Montserrat";
          }
        }
        #map {
          height: 100%;
        }
        .map-div{
          height: 400px !important;
        }
        .search {
          .vs__dropdown-menu {
            max-height: 170px !important;
            overflow-y: auto !important;
            width: 320px !important;
          }
          .vs__dropdown-toggle{
            max-height: 60px !important;
            overflow-y: auto !important;
            width: 320px !important;
          }
        }
        .search1 {
          .vs__dropdown-menu {
            max-height: 150px !important;
            overflow-y: auto !important;
            width: 380px !important;
          }
          .vs__dropdown-toggle{
            max-height: 60px !important;
            overflow-y: auto !important;
            width: 380px !important;
          }
        }
        .vs__search{
          height: 35px !important;
        }
        .v-select.vs--single .vs__selected{
          font-size: 1.1rem;
          color: #6e6b7b;
          font-weight: 400;
          font-family: "Montserrat";
        }
        #form {
          .custom-control {
            min-width: 145px !important;
            max-width: 145px !important;
          }
        }
        .heading{
          font-size: 20px;
          line-height: 44px;
          color: #1f58b5;
          font-weight: 600;
          font-family: "Montserrat",serif;
          text-align: center;
        }
        //.form-control{
        //  height: 42px !important;
        //}
        .campaign-label{
          font-size: 13px;
          line-height: 18px;
          color: #1f58b5;
          font-weight: 600;
          font-family: "Montserrat";
        }
        #calender {
          .dropdown-menu{
            z-index: 12 !important;
          }
        }
        #time{
          .vue__time-picker .controls .char{
            display: none !important;
          }
        }
        .closediv2{
          position: relative;
          border-radius: 50%;
          bottom: 20px !important;
          left: 27px !important;
          top: auto;
          cursor: pointer;
        }
      .vc-bars{
        width: 20% !important;
      }
      .vc-day-content:focus {
        font-weight: normal !important;
      }
      .text-id{
        font-size: medium;
        max-width: 200px !important;
        margin-bottom: 0px !important;
      }
      .badgeSvg{
        height: 15px !important;
        width: 15px !important;
      }
      .btn-cross1{
        border-radius: 50%;
        margin: 0px;
        padding: 0px;
        top: none !important;
        right: none !important;
        left: none !important;
      }
      .btn-cross1:hover{
        color: #ea5455 !important;
      }
      .card2{
        width: 100%;
        //height: 185px;
        border-radius: 10px;
        background-color: #eeeeee;
        margin-right: 41px;
        //overflow-y: scroll;
      }
      .labelSelectedDevices{
        font-size: large;
      }
      @media (max-width: 750px) {
        .classButton{
          font-size: 12px;
        }
        .classButtonIcon{
          display: none;
        }
        .labelSelectedDevices{
          font-size: small;
        }
      }
    }
    </style>
